import { FC, useEffect, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import useFindProperties from "hooks/properties/useFindProperties";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StayCardHSkeleton from "components/StayCardH/StayCardHSkeleton";
import useGetQueryParams from "hooks/useGetQueryParams";

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const query = useGetQueryParams();

  const pageSize = 8;
  const {
    data,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useFindProperties(pageSize, query);
  useEffect(() => {
    console.log(data);
  }, [data]);
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading={`Stays in ${query.city ? query.city : "all México"}`}
          />
          <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div>
          <div className="grid grid-cols-1 gap-8">
            {data && !isLoading
              ? data.pages.map((p) =>
                  p.results.map((property) => {
                    return (
                      <div
                        key={property.property_id}
                        onMouseEnter={() =>
                          setCurrentHoverID((_) => property.property_id)
                        }
                        onMouseLeave={() => setCurrentHoverID((_) => -1)}
                      >
                        <StayCardH data={property} />
                      </div>
                    );
                  })
                )
              : Array.from({ length: 8 }, (_, i) => (
                  <div key={i}>
                    <StayCardHSkeleton className="animate-pulse" />
                  </div>
                ))}
            {data && data.pages.length === 0 && (
              <div className="text-center text-lg text-gray-500">
                No stays found
              </div>
            )}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <ButtonPrimary
              onClick={() => {
                fetchNextPage();
              }}
              sizeClass="px-4 py-2 sm:px-5"
              disabled={!hasNextPage}
            >
              {isFetchingNextPage
                ? "Loading..."
                : hasNextPage
                ? "Load more"
                : "No more stays"}
            </ButtonPrimary>
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div>

            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
            {isLoading ? (
              <span>Loading map...</span>
            ) : (
              data &&
              data.pages[0].results.length !== 0 && (
                <GoogleMapReact
                  defaultZoom={12}
                  defaultCenter={{
                    lat: data.pages[0].results[0].lat,
                    lng: data.pages[0].results[0].lng,
                  }}
                  bootstrapURLKeys={{
                    key: "AIzaSyD2fzmBVhIkw7AgrIY_tIhNxMl4nB3rAmc",
                  }}
                  yesIWantToUseGoogleMapApiInternals
                >
                  {data?.pages.map((p) =>
                    p.results.map((item) => (
                      <AnyReactComponent
                        isSelected={currentHoverID === item.property_id}
                        key={item.property_id}
                        lat={item.lat}
                        lng={item.lng}
                        listing={item}
                      />
                    ))
                  )}
                </GoogleMapReact>
              )
            )}
            {data && data.pages[0].results.length === 0 && (
              <div className="flex justify-center items-center h-full">
                <span className="text-lg text-gray-500">No stays found</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
