import { Amenity } from "hooks/properties/useAmenities";
import { PropertyRule } from "hooks/properties/usePropertyRules";
import { Image } from "services/imagesService";
import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { AddressCoord } from "hooks/usePlacesAutocomplete";
import { Tag } from "hooks/properties/useTags";
import { Reservation } from "data/types";

interface AddListingStore {
  type: number;
  category: string;
  name: string;
  rental: string;
  tags: Tag[];
  country: string;
  address: string;
  roomNumber: string;
  city: string;
  state: string;
  postal_code: string;
  coords: AddressCoord;
  guest: number;
  bedrooms: number;
  beds: number;
  bathrooms: number;
  kitchen: number;
  amenities: Amenity[];
  propertyRules: PropertyRule[];
  description: string;
  coverImage: Image;
  images: Image[];
  currency: string;
  lowSeason: number;
  midSeason: number;
  highSeason: number;
  holyDays: number;
  minNights: number;
  maxNights: number;
  reservations: Reservation[];
  setProperty: (key: keyof AddListingStore, value: any) => void;
  addAmenity: (amenity: Amenity) => void;
  deleteAmenity: (id: string) => void;
  addRule: (rule: PropertyRule) => void;
  deleteRule: (id: string) => void;
  addImages: (url: string) => void;
  addCoverImage: (url: string) => void;
  addTag: (tag: Tag) => void;
  deleteTag: (id: number) => void;
  resetStore: () => void;

  addReservation: (reservation: Reservation) => void;
}

const initialState: AddListingStore = {
  type: 0,
  category: "1",
  name: "",
  rental: "",
  tags: [],
  country: "",
  address: "",
  roomNumber: "",
  city: "",
  state: "",
  postal_code: "",
  coords: { lat: 0, lng: 0 },
  guest: 0,
  bedrooms: 0,
  beds: 0,
  bathrooms: 0,
  kitchen: 0,
  amenities: [],
  propertyRules: [],
  description: "",
  coverImage: { url: "", id: 0 },
  images: [],
  currency: "MXN",
  lowSeason: 0,
  midSeason: 0,
  highSeason: 0,
  holyDays: 0,
  maxNights: 0,
  minNights: 0,
  reservations: [],
  setProperty: () => {},
  addAmenity: () => {},
  deleteAmenity: () => {},
  addRule: () => {},
  deleteRule: () => {},
  addImages: () => {},
  addCoverImage: () => {},
  addTag: () => {},
  deleteTag: () => {},
  resetStore: () => {},
  addReservation: () => {},
};

const useAddListingStore = create<AddListingStore>((set) => ({
  ...initialState,
  setProperty: (key, value) => set((state) => ({ ...state, [key]: value })),
  addAmenity: (amenity: Amenity) =>
    set((state) => ({
      amenities: [...state.amenities, amenity],
    })),
  deleteAmenity: (id: string) =>
    set((state) => ({
      amenities: state.amenities.filter((a) => a.amenity_id !== id),
    })),
  addRule: (rule: PropertyRule) =>
    set((state) => ({
      propertyRules: [...state.propertyRules, rule],
    })),
  deleteRule: (id: string) =>
    set((state) => ({
      propertyRules: state.propertyRules.filter((r) => r.rule_id !== id),
    })),
  addImages: (url: string) =>
    set((state) => ({
      images: [...state.images, { id: 0, url: url }],
    })),
  addCoverImage: (url: string) =>
    set((state) => ({
      coverImage: { id: 1, url: url },
    })),
  addTag: (Tag: Tag) =>
    set((state) => ({
      tags: [...state.tags, Tag],
    })),
  deleteTag: (id: number) =>
    set((state) => ({
      tags: state.tags.filter((t) => t.tag_id !== id),
    })),
  resetStore: () => set({ ...initialState }),
  addReservation: (reservation: Reservation) =>
    set((state) => ({
      reservations: [...state.reservations, reservation],
    })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("listing", useAddListingStore);
}

export default useAddListingStore;
