import { Currency, Rate } from "data/types";
import convertNumbThousand from "./convertNumbThousand";
import { toInteger } from "lodash";

function getPricePerNight(
  rates: Rate[],
  startDate: Date,
  endDate: Date
): { Number: number; Text: string } {
  // Convertimos las fechas de inicio y fin a solo el mes y día, ignorando el año
  // const startMonthDay = `${startDate.getMonth() + 1}-${startDate.getDate()}`;
  // const endMonthDay = `${endDate.getMonth() + 1}-${endDate.getDate()}`;

  // Inicializamos variables para acumular el precio total y contar los días
  let totalPrice = 0;
  let totalDays = 0;
  let currency: Currency | null = null;

  // Función para extraer el mes y día de una fecha
  const getMonthDay = (date: Date) =>
    `${date.getMonth() + 1}-${date.getDate()}`;

  // Recorremos cada día en el rango de fechas
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const currentMonthDay = getMonthDay(new Date(d)); // Obtenemos el mes y día del día actual

    // Buscamos la tarifa para la fecha actual ignorando el año
    for (const rate of rates) {
      const seasonStartMonthDay = getMonthDay(new Date(rate.season.start_date));
      const seasonEndMonthDay = getMonthDay(new Date(rate.season.end_date));

      // Verificamos si la fecha actual cae dentro del rango de la temporada, ignorando el año
      if (seasonEndMonthDay < seasonStartMonthDay) {
        // Si la temporada abarca fin de año (por ejemplo, del 15 de diciembre al 10 de enero)
        if (
          currentMonthDay >= seasonStartMonthDay ||
          currentMonthDay <= seasonEndMonthDay
        ) {
          totalPrice += rate.price_per_night;
          totalDays += 1;
          if (!currency) {
            currency = rate.currency;
          }
          break; // Salimos del bucle ya que encontramos la tarifa correspondiente
        }
      } else {
        if (
          currentMonthDay >= seasonStartMonthDay &&
          currentMonthDay <= seasonEndMonthDay
        ) {
          totalPrice += rate.price_per_night;
          totalDays += 1;
          if (!currency) {
            currency = rate.currency;
          }
          break; // Salimos del bucle ya que encontramos la tarifa correspondiente
        }
      }
    }
  }

  // Calculamos el precio promedio por noche
  const averagePricePerNight = totalDays > 0 ? totalPrice / totalDays : 0;

  // Devolvemos el precio formateado con la moneda
  return {
    Number: toInteger(averagePricePerNight),
    Text: `${convertNumbThousand(averagePricePerNight)} ${currency}`,
  };
}

export default getPricePerNight;
