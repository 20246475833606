import React from "react";
import { IconType } from "react-icons";
import {
  FaFan,
  FaParking,
  FaSwimmingPool,
  FaToiletPaper,
  FaMountain,
  FaGolfBall,
  FaHotTub,
  FaUmbrellaBeach,
  FaFireExtinguisher,
  FaUserTie,
  FaDumbbell,
  FaAnchor,
  FaTableTennis,
  FaTv,
  FaWifi,
  FaWater,
} from "react-icons/fa";
import { PiHairDryer, PiThermometerHotThin, PiElevator } from "react-icons/pi";
import {
  TbToolsKitchen3,
  TbDesk,
  TbFridge,
  TbAirConditioning,
} from "react-icons/tb";
import { GiToaster, GiWashingMachine } from "react-icons/gi";
import { BiSolidWasher } from "react-icons/bi";
import { RiSafe2Line, RiBilliardsFill } from "react-icons/ri";
import { FaSpa } from "react-icons/fa6";
import { MdBalcony } from "react-icons/md";

const amenityIcons: { [key: string]: IconType } = {
  Wifi: FaWifi,
  "Air conditioning": TbAirConditioning,
  Dryer: PiHairDryer,
  TV: FaTv,
  "Parking lot": FaParking,
  "Washing area": GiWashingMachine,
  "Equipped kitchen": TbToolsKitchen3,
  Desk: TbDesk,
  Fridge: TbFridge,
  Fan: FaFan,
  "BBQ grill": FaFan,
  "Bathroom heaters": PiThermometerHotThin,
  Toaster: GiToaster,
  Dishwasher: BiSolidWasher,
  "Toilet paper": FaToiletPaper,
  "Fire siren": FaFireExtinguisher,
  "Fire extinguisher": FaFireExtinguisher,
  "Safe vault": RiSafe2Line,
  Seaview: FaWater,
  "Golf course view": FaGolfBall,
  "Mountain view": FaMountain,
  Butler: FaUserTie,
  SPA: FaSpa,
  GYM: FaDumbbell,
  Elevator: PiElevator,
  Dock: FaAnchor,
  "Ping pong table": FaTableTennis,
  Terrace: MdBalcony,
  Billiards: RiBilliardsFill,
  Balcony: MdBalcony,
  Jacuzzi: FaHotTub,
  Pool: FaSwimmingPool,
  "Private pool": FaSwimmingPool,
  "Beach access": FaUmbrellaBeach,
};

interface AmenityIconProps {
  amenity: string;
}

const AmenityIcon: React.FC<AmenityIconProps> = ({ amenity }) => {
  const IconComponent = amenityIcons[amenity];

  return <IconComponent className="text-3xl las" />;
};

export default AmenityIcon;
