import { Amenity } from "hooks/properties/useAmenities";
import { PropertyRule } from "hooks/properties/usePropertyRules";
import { Image } from "./imagesService";
import ApiClient from "./apiClient";
import { AddressCoord } from "hooks/usePlacesAutocomplete";
import { Tag } from "hooks/properties/useTags";
import { Reservation } from "data/types";

export interface Property {
  type: number;
  category: string;
  name: string;
  tags: Tag[];
  country: string;
  address: string;
  roomNumber: string;
  city: string;
  state: string;
  postal_code: number;
  coords: AddressCoord;
  guest: number;
  bedrooms: number;
  beds: number;
  bathrooms: number;
  kitchen: number;
  amenities: Amenity[];
  propertyRules: PropertyRule[];
  description: string;
  images: Image[];
  lowSeason: number;
  midSeason: number;
  highSeason: number;
  currency: string;
  holyDays: number;
  min_nights: number;
  max_nights: null;
  reservation: Reservation[];
}

const service = new ApiClient<Property>("/Properties");

export default service;
