import StayCard from "components/StayCard/StayCard";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { StayDataType } from "data/types";
import useAddListingStore from "stores/addListinigStore";
import { Property } from "services/PropertyService";
import { Amenity } from "hooks/properties/useAmenities";
import { PropertyRule } from "hooks/properties/usePropertyRules";
import { Image } from "services/imagesService";
import { useNavigate } from "react-router-dom";
import useAddProperty from "hooks/properties/useProperties";

export interface PageAddListing10Props {}

const PageAddListing10: FC<PageAddListing10Props> = () => {
  const listing = useAddListingStore();
  const navegation = useNavigate();
  const addLisiting = useAddProperty(() => {
    navegation("/");
  });

  const images: string[] = [];
  listing.images.forEach((imagen) => {
    images.push(imagen.url);
  });

  const ListingPreview: StayDataType = {
    property_id: 0,
    address: `${listing.country} ${listing.state} ${listing.city}`,
    title: listing.name,
    images: [],
    max_guests: listing.guest.toString(),
    number_of_bedrooms: listing.bedrooms.toString(),
    number_of_baths: listing.bathrooms.toString(),
    lat: listing.coords?.lat,
    lng: listing.coords?.lng,
    category_description: listing.category,
    category_id: listing.category,
    city: listing.city,
    country: listing.country,
    description: listing.description,
    max_nights: listing.maxNights,
    min_nights: listing.minNights,
    kitchen: listing.kitchen,
    number_of_beds: listing.beds.toString(),
    postal_code: listing.postal_code,
    room_number: listing.roomNumber.toString(),
    zone: "",
    rates: [],
    rating: null,
    state: listing.state,
    tags: [],
    type: listing.type,
  };

  const preparePropertyData = (storeData: any): Property => {
    return {
      category: storeData.category,
      type: storeData.type,
      name: storeData.name,
      tags: storeData.tags,
      country: storeData.country,
      address: storeData.address,
      roomNumber: storeData.roomNumber,
      city: storeData.city,
      state: storeData.state,
      postal_code: parseInt(storeData.postal_code, 10),
      coords: {
        lat: storeData.coords?.lat ?? 0,
        lng: storeData.coords?.lng ?? 0,
      },
      guest: storeData.guest,
      bedrooms: storeData.bedrooms,
      beds: storeData.beds,
      bathrooms: storeData.bathrooms,
      kitchen: storeData.kitchen,
      amenities: storeData.amenities.map((amenity: Amenity) => ({
        amenity_id: parseInt(amenity.amenity_id, 10),
        amenity_name: amenity.amenity_name,
        category: amenity.category,
      })),
      propertyRules: storeData.propertyRules.map((rule: PropertyRule) => ({
        rule_id: parseInt(rule.rule_id, 10),
        rule_name: rule.rule_name,
      })),
      description: storeData.description,
      images: storeData.images.map((image: Image) => ({
        url: image.url,
      })),
      currency: storeData.currency,
      lowSeason: storeData.lowSeason,
      midSeason: storeData.midSeason,
      highSeason: storeData.highSeason,
      holyDays: storeData.holyDays,
      min_nights: storeData.minNights,
      reservation: storeData.reservations,
      max_nights: storeData.maxNights,
    };
  };

  const handleSubmit = async () => {
    const propertyData = preparePropertyData(listing);
    console.log(propertyData);
    addLisiting.mutate(propertyData);
    listing.resetStore();
  };

  return (
    <CommonLayout
      nextBtnText="Publish listing"
      index="10"
      backtHref="/add-listing-9"
      nextHref="/"
      submit
      onSubmit={handleSubmit}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Congratulations 🎉</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Excellent, congratulations on completing the listing, it is waiting
            to be reviewed for publication
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div>
          <h3 className="text-lg font-semibold">This is your listing</h3>
          <div className="max-w-xs">
            <StayCard className="mt-8" data={{ ...ListingPreview }} />
          </div>
          <div className="flex items-center space-x-5 mt-8">
            <ButtonSecondary href="/add-listing-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Edit</span>
            </ButtonSecondary>

            <ButtonPrimary>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
              <span className="ml-3">Preview</span>
            </ButtonPrimary>
          </div>
        </div>
        {/*  */}
      </>
    </CommonLayout>
  );
};

export default PageAddListing10;
