import { FC } from "react";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  pages?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  currentPage,
  onPageChange,
  pages,
}) => {
  const renderItem = (index: number, currentPage: number) => {
    if (index === currentPage) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {index}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <button
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        onClick={() => onPageChange && onPageChange(index)}
      >
        {index}
      </button>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {
        // RENDER PAGINATION
        Array.from({ length: pages ? pages : 0 }).map((_, index) =>
          renderItem(index + 1, currentPage ? currentPage : 0)
        )
      }
    </nav>
  );
};

export default Pagination;
