import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ApiClient from "services/apiClient";
import useDebounce from "./useDebounce";
import useFilterStore from "stores/FilterStore";

export interface nameType {
  title: string;
}

const apiClient = new ApiClient<nameType[]>("/PropertiesByName");

const usePlacesFind = () => {
  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce(value, 500); // Ajusta el delay según tus necesidades
  const { type } = useFilterStore();
  const { data, isLoading, error } = useQuery(
    ["LocationSearch", debouncedValue, type],
    () =>
      apiClient.getAllWithParams({
        params: {
          type: type,
          query: debouncedValue,
        },
      }),
    {
      enabled: !!debouncedValue, // Solo ejecuta la consulta si hay un valor debounced
    }
  );

  return { data, isLoading, error, value, setValue };
};

export default usePlacesFind;
