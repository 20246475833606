import { useMutation, useQueryClient } from "@tanstack/react-query";
import { WishlistItem } from "services/WishlistService";
import addWishlistItem from "services/WishlistService";

const useAddWishList = (onAdd: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addWishlistItem.Post,
    onMutate: (newItem: WishlistItem) => {
      const previousWishlist =
        queryClient.getQueryData<WishlistItem[]>(["wishlist"]) || [];
      queryClient.setQueryData<WishlistItem[]>(["wishlist"], (wishlist) => [
        newItem,
        ...(wishlist || []),
      ]);
      return { previousWishlist };
    },
    onSuccess: (newItem, _variables) => {
      queryClient.setQueryData<WishlistItem[]>(["wishlist"], (wishlist) => {
        return wishlist?.map((item) => (item === newItem ? newItem : item));
      });
      onAdd();
    },
    onError: (_error, _newItem, context) => {
      if (!context) return;
      queryClient.setQueryData<WishlistItem[]>(
        ["wishlist"],
        context.previousWishlist
      );
    },
  });
};

export default useAddWishList;
