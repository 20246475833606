import useGetWishlist from "hooks/users/useGetWishlist";
import React from "react";
import MyRouter from "routers/index";
import useUserStore from "stores/userStore";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function App() {
  const {
    setUser,
    setIsLogged,
    user: { user_id },
    setUserWishlist,
  } = useUserStore();
  const { data } = useGetWishlist(user_id);
  React.useEffect(() => {
    const user = localStorage.getItem("User");
    if (user) {
      setUser(JSON.parse(user));
      setIsLogged(true);

      if (data) {
        const wishlist = data.map((property: any) => property.property_id);
        setUserWishlist(wishlist);
      }
    }
  }, [setUser, data]);

  return (
    <GoogleOAuthProvider clientId="752729360207-9e9afkpdinf5rsht6l1dmdluj673dcrd.apps.googleusercontent.com">
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>
      {/* <ReactQueryDevtools /> */}
    </GoogleOAuthProvider>
  );
}

export default App;
