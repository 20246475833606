import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "services/apiClient";
import { WishlistItem } from "services/WishlistService";

type RemoveWishlistItem = {
  userId: number;
  property_id: number;
};

const useAddWishList = (onRemove: () => void) => {
  const queryClient = useQueryClient();
  const removeWishlistItem = new ApiClient<RemoveWishlistItem>("/WishList");
  return useMutation({
    mutationFn: ({ userId, property_id }: RemoveWishlistItem) =>
      removeWishlistItem.deleteWithParams({
        params: { userId, property_id },
      }),
    onMutate: async ({ userId, property_id }: RemoveWishlistItem) => {
      await queryClient.cancelQueries(["wishlist", userId]);

      const previousWishlist =
        queryClient.getQueryData<WishlistItem[]>(["wishlist", userId]) || [];

      // Actualización optimista
      queryClient.setQueryData<WishlistItem[]>(
        ["wishlist", userId],
        (wishlist) =>
          wishlist?.filter((item) => item.property_id !== property_id) || []
      );

      return { previousWishlist };
    },
    onSuccess: (_, variables) => {
      // Confirma la eliminación
      queryClient.setQueryData<WishlistItem[]>(
        ["wishlist", variables.userId],
        (wishlist) =>
          wishlist?.filter(
            (item) => item.property_id !== variables.property_id
          ) || []
      );
      onRemove();
    },
    onError: (_error, _variables, context) => {
      if (!context) return;
      queryClient.setQueryData<WishlistItem[]>(
        ["wishlist", context.previousWishlist?.[0]?.user_id],
        context.previousWishlist
      );
    },
  });
};

export default useAddWishList;
