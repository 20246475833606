import { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import useAddListingStore from "stores/addListinigStore";

export interface PageAddListing8Props {}

const PageAddListing8: FC<PageAddListing8Props> = () => {
  const { currency, highSeason, midSeason, lowSeason, holyDays, setProperty } =
    useAddListingStore();
  return (
    <CommonLayout
      index="08"
      backtHref="/add-listing-7"
      nextHref="/add-listing-9"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Price your space</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The host's revenue is directly dependent on the setting of rates and
            regulations on the number of guests, the number of nights, and the
            cancellation policy.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem label="Currency">
            <Select
              value={currency}
              onChange={(e) => setProperty("currency", e.target.value)}
            >
              <option value="MXN">MXN</option>
              <option value="USD">USD</option>
            </Select>
          </FormItem>
          <div className="flex flex-row ">
            <FormItem label="Low season">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">$</span>
                </div>
                <Input
                  value={lowSeason}
                  onChange={(e) => setProperty("lowSeason", e.target.value)}
                  className="!pl-8 !pr-10"
                  placeholder="0.00"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">{currency}</span>
                </div>
              </div>
            </FormItem>
            <FormItem label="mid-season" className="mx-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">$</span>
                </div>
                <Input
                  value={midSeason}
                  onChange={(e) => setProperty("midSeason", e.target.value)}
                  className="!pl-8 !pr-10"
                  placeholder="0.00"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">{currency}</span>
                </div>
              </div>
            </FormItem>
            <FormItem label="high season">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">$</span>
                </div>
                <Input
                  value={highSeason}
                  onChange={(e) => setProperty("highSeason", e.target.value)}
                  className="!pl-8 !pr-10"
                  placeholder="0.00"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">{currency}</span>
                </div>
              </div>
            </FormItem>
          </div>
          {/* ----- */}
          <div className="flex flex-row">
            <FormItem className="basis-1/2 me-4" label="Holydays (opcional)">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">$</span>
                </div>
                <Input
                  value={holyDays}
                  onChange={(e) => setProperty("holyDays", e.target.value)}
                  className="!pl-8 !pr-10"
                  placeholder="0.00"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">{currency}</span>
                </div>
              </div>
            </FormItem>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing8;
