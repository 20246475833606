import { FC } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import CommonLayout from "./CommonLayout";
import useAmenities, { Amenity } from "hooks/properties/useAmenities";
import useAddListingStore from "stores/addListinigStore";

export interface PageAddListing4Props {}

const PageAddListing4: FC<PageAddListing4Props> = () => {
  const {
    addAmenity,
    deleteAmenity,
    amenities: amenidades,
  } = useAddListingStore();
  const { data: amenities, error, isLoading } = useAmenities();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // divide amenities into 3 categories General, Other, Safe
  const generalAmenities = amenities?.filter(
    (amenity) => amenity.category === "General"
  );
  const otherAmenities = amenities?.filter(
    (amenity) => amenity.category === "Other"
  );
  const safeAmenities = amenities?.filter(
    (amenity) => amenity.category === "Safe"
  );

  const handleAddAmenity = (amenity: Amenity, active: boolean) => {
    if (active) {
      addAmenity(amenity);
    } else {
      deleteAmenity(amenity.amenity_id);
    }
  };

  return (
    <CommonLayout
      index="04"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on amenities
            criteria
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              General amenities
            </label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {!isLoading &&
                generalAmenities?.map((amenity) => (
                  <Checkbox
                    onChange={(e) => handleAddAmenity(amenity, e)}
                    label={amenity.amenity_name}
                    name={amenity.amenity_name}
                    key={amenity.amenity_id}
                    defaultChecked={amenidades?.some(
                      (i) => i.amenity_id === amenity.amenity_id
                    )}
                  />
                ))}
            </div>
          </div>

          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              Other amenities
            </label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {!isLoading &&
                otherAmenities?.map((amenity) => (
                  <Checkbox
                    onChange={(e) => handleAddAmenity(amenity, e)}
                    label={amenity.amenity_name}
                    name={amenity.amenity_name}
                    key={amenity.amenity_id}
                    defaultChecked={amenidades?.some(
                      (i) => i.amenity_id === amenity.amenity_id
                    )}
                  />
                ))}
            </div>
          </div>

          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              Safe amenities
            </label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {!isLoading &&
                safeAmenities?.map((amenity) => (
                  <Checkbox
                    onChange={(e) => handleAddAmenity(amenity, e)}
                    label={amenity.amenity_name}
                    name={amenity.amenity_name}
                    key={amenity.amenity_id}
                    defaultChecked={amenidades?.some(
                      (i) => i.amenity_id === amenity.amenity_id
                    )}
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
