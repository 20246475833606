import ApiClient from "./apiClient";

export interface WishlistItem {
  wishlist_id: number;
  user_id: number;
  property_id: number;
}

const service = new ApiClient<WishlistItem>("/WishList");

export default service;
