import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import { Link, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { Blog_Data_By_Id } from "data/blogs";
import React, { ReactElement } from "react";

const BlogSingle = () => {
  // get id from url
  const { id } = useParams<{ id: string }>();

  const Data = Blog_Data_By_Id[id ? parseInt(id) : 0];

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {Data.title}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {Data.description}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    Pvr Properties
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    Aug 08, 2024
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    const processText = (text: string) => {
      // Split text by newline and map to elements
      const parts = text.split("\n").map((part, index) => {
        // Replace *text* with <strong>text</strong>
        const processedPart = part.replace(
          /\*(.*?)\*/g,
          (match, p1) => `<strong>${p1}</strong>`
        );
        return (
          <span
            key={index}
            dangerouslySetInnerHTML={{ __html: processedPart }}
          />
        );
      });

      // Insert <br /> for each newline
      return parts.reduce(
        (acc, curr) =>
          acc.length === 0 ? [curr] : [...acc, <br key={acc.length} />, curr],
        [] as ReactElement<any, any>[]
      );
    };
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        {Data.content.map((item, index) => {
          if (item.type === "paragraph") {
            return (
              <React.Fragment key={index}>
                <h2>{item.Subtitle}</h2>
                <p>{processText(item.content ? item.content : "")}</p>
                {item.Imagen && (
                  <figure>
                    <img
                      src={item.Imagen}
                      alt="nc blog"
                      className="rounded-2xl"
                    />
                  </figure>
                )}
              </React.Fragment>
            );
          } else if (item.type === "list") {
            return (
              <React.Fragment key={index}>
                <h2>{item.Subtitle}</h2>
                <ul>
                  {item.List?.map((listItem, listIndex) => (
                    <li key={listIndex}>
                      <h3>{listItem.title}</h3>
                      <p>
                        {processText(
                          listItem.description ? listItem.description : ""
                        )}
                      </p>
                      {listItem.image && (
                        <figure>
                          <img
                            src={listItem.image}
                            alt="nc blog"
                            className="rounded-2xl"
                          />
                        </figure>
                      )}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        {Data.tags.map((tag, index) => {
          return (
            <a
              key={index}
              className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
              href="##"
            >
              {tag}
            </a>
          );
        })}
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">PVR PROPERTIES</a>
            </h2>
          </div>
        </div>
      </div>
    );
  };

  // const renderCommentForm = () => {
  //   return (
  //     <div className="max-w-screen-md mx-auto pt-5">
  //       <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
  //         Responses (14)
  //       </h3>
  //       <form className="nc-SingleCommentForm mt-5">
  //         <Textarea />
  //         <div className="mt-2 space-x-3">
  //           <ButtonPrimary>Submit</ButtonPrimary>
  //           <ButtonSecondary>Cancel</ButtonSecondary>
  //         </div>
  //       </form>
  //     </div>
  //   );
  // };

  // const renderCommentLists = () => {
  //   return (
  //     <div className="max-w-screen-md mx-auto">
  //       <ul className="nc-SingleCommentLists space-y-5">
  //         <li>
  //           <Comment />
  //           <ul className="pl-4 mt-5 space-y-5 md:pl-11">
  //             <li>
  //               <Comment isSmall />
  //             </li>
  //           </ul>
  //         </li>
  //         <li>
  //           <Comment />
  //           <ul className="pl-4 mt-5 space-y-5 md:pl-11">
  //             <li>
  //               <Comment isSmall />
  //             </li>
  //           </ul>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Single Blog || Booking React Template</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={Data.image}
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
      </div>
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  */}
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSingle;
