import React, { useState } from "react";
import { Combobox } from "@headlessui/react";
import { FaCheck } from "react-icons/fa";
import { HiSelector } from "react-icons/hi";
import { Tag } from "hooks/properties/useTags";

interface TagSelectorProps {
  tags: Tag[];
  onChange: (tags: Tag) => void;
  onRemove: (id: number) => void;
}

const TagSelector: React.FC<TagSelectorProps> = ({
  tags,
  onChange,
  onRemove,
}) => {
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [query, setQuery] = useState("");
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  const filteredTags =
    query === ""
      ? tags
      : tags
          .filter((tag) =>
            tag.tag_name.toLowerCase().includes(query.toLowerCase())
          )
          .filter((tag) => !selectedTags.some((t) => t.tag_id === tag.tag_id));

  const handleSelect = (tag: Tag) => {
    if (!selectedTags.some((t) => t.tag_id === tag.tag_id)) {
      setSelectedTags([...selectedTags, tag]);
      onChange(tag);
    }
    setSelectedTag(null);
  };

  const handleRemove = (tag: Tag) => {
    setSelectedTags(selectedTags.filter((t) => t.tag_id !== tag.tag_id));
    onRemove(tag.tag_id);
  };
  return (
    <div className="w-72">
      <Combobox value={selectedTag} onChange={handleSelect}>
        <div className="relative mt-1">
          <div className="relative w-full text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-300 focus:ring-white sm:text-sm">
            <Combobox.Input
              className="w-full border-none focus:ring-0"
              displayValue={(tag: Tag | null) => (tag ? tag.tag_name : "")}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Select tags"
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <HiSelector
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredTags.length === 0 && query !== "" ? (
              <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredTags.map((tag) => (
                <Combobox.Option
                  key={tag.tag_id}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${
                      active ? "text-white bg-primary-600" : "text-gray-900"
                    }`
                  }
                  value={tag}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {tag.tag_name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-primary-600"
                          }`}
                        >
                          <FaCheck className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
      <div className="mt-2 flex flex-wrap">
        {selectedTags.map((tag) => (
          <span
            key={tag.tag_id}
            className="flex items-center px-3 py-1 m-1 text-sm font-medium text-white bg-primary-600 rounded-full"
          >
            {tag.tag_name}
            <button
              type="button"
              className="ml-1 text-white focus:outline-none"
              onClick={() => handleRemove(tag)}
            >
              &times;
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagSelector;
