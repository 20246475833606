import FormItem from "./FormItem";
import Input from "shared/Input/Input";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useEffect, useRef, useState } from "react";
import usePlacesAutoComplete from "hooks/usePlacesAutocomplete";
import { AddressLocation } from "hooks/usePlacesAutocomplete";

interface PlacesAutoCompleteProps {
  setSelected: (location: AddressLocation) => void;
}

const PlacesAutoComplete = ({ setSelected }: PlacesAutoCompleteProps) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const Combobox = useRef<HTMLUListElement>(null);

  useOutsideAlerter(Combobox, () => {
    setDisplayOptions(false);
  });

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setDisplayOptions(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { value, setValue, data, ready, status, getLocations } =
    usePlacesAutoComplete();

  useEffect(() => {
    if (value === "") {
      setDisplayOptions(false);
    } else {
      setDisplayOptions(true);
    }
  }, [value]);

  const handleSelect = async (value: string) => {
    setDisplayOptions(false);
    const location: AddressLocation = await getLocations(value);
    console.log("Location: ", location);
    setSelected(location);
  };

  return (
    <FormItem label="Location">
      <Input
        value={value}
        disabled={!ready}
        placeholder="Search your location"
        onFocus={() => setDisplayOptions(true)}
        onChange={(e) => setValue(e.target.value)}
      />
      {displayOptions && (
        <ul
          ref={Combobox}
          className="py-2 text-sm text-gray-700 dark:text-gray-200 rounded-lg shadow"
        >
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <li
                onClick={() => handleSelect(description)}
                className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                key={place_id}
                value={description}
              >
                {description}
              </li>
            ))}
        </ul>
      )}
    </FormItem>
  );
};

export default PlacesAutoComplete;
