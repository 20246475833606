import React from "react";
import { ReactNode } from "react";
import useFilterStore from "stores/FilterStore";
import converSelectedDateToString from "utils/converSelectedDateToString";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "Stays in Tokyo",
  subHeading,
}) => {
  const { check_in, check_out, adults, children, infants } = useFilterStore();

  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          233 stays
          <span className="mx-2">·</span>
          {converSelectedDateToString([
            check_in ? check_in : new Date(),
            check_out ? check_out : new Date(),
          ])}{" "}
          - {}
          <span className="mx-2">·</span>
          {adults ? `Adults: ${adults}` : ""}
          <span className="mx-2">·</span>
          {children ? `Childrens: ${children}` : ""}
          <span className="mx-2">·</span>
          {infants ? `Infants: ${infants}` : ""}
        </span>
      )}
    </div>
  );
};

export default Heading2;
