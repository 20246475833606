import useOutsideAlerter from "hooks/useOutsideAlerter";
import React, { useEffect, useState } from "react";
import ButtonSubmit from "../ButtonSubmit";
import Checkbox from "shared/Checkbox/Checkbox";
import { FC } from "react";
import useFilterStore, { Filter } from "stores/FilterStore";
import { useNavigate } from "react-router-dom";

interface SalesTypeListingProps {
  className?: string;
  fieldClassName?: string;
  autoFocus?: boolean;
  submitLink: string;
}

const SalesTypeListing: FC<SalesTypeListingProps> = ({
  fieldClassName = "[ nc-hero-field-padding--small ]",
  className = "",
  autoFocus = false,
  submitLink,
}) => {
  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(refContainer, () => setIsOpen(false));

  const [typeOfProperty, setTypeOfProperty] = useState({
    name: "Department",
    id: "3",
  });

  const navigate = useNavigate();
  const { setFilter, category, type, city, state, zone, min_price, max_price } =
    useFilterStore();

  useEffect(() => {
    setFilter("category", typeOfProperty.id);
  }, [typeOfProperty]);

  const handleSubmitFilter = () => {
    const filters: Filter = {
      min_price: min_price,
      max_price: max_price,
      zone: zone,
      state: state,
      city: city,
      category: category?.toString(),
      type: type,
    };

    const filteredParams: Partial<Filter> = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc[key as keyof Filter] = value;
        }
        return acc;
      },
      {} as Partial<Filter>
    );
    const queryString = new URLSearchParams(
      filteredParams as Record<string, string>
    ).toString();
    navigate(`/listing-stay?${queryString}`);
  };

  return (
    <div className={`flex z-10 relative ${className}`} ref={refContainer}>
      <div
        className={`flex z-10 relative flex-1 text-left justify-between items-center focus:outline-none cursor-pointer ${
          isOpen ? "nc-hero-field-focused--2" : ""
        }`}
      >
        <div
          className={`${fieldClassName} flex-1`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex-1 text-left">
            <span className="block font-semibold">
              {typeOfProperty.name || `Type`}
            </span>
            <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
              Property type
            </span>
          </div>
        </div>
        <div className="pr-2">
          <ButtonSubmit handleClick={handleSubmitFilter} />
        </div>
      </div>
      {isOpen && (
        <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-10 bg-white dark:bg-neutral-800"></div>
      )}

      {isOpen && (
        <div className="absolute right-0 z-30 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
          <div className="">
            <div className="relative flex flex-col space-y-5">
              <Checkbox
                name={"department"}
                label={"Department"}
                defaultChecked={typeOfProperty.id === "3"}
                onChange={() =>
                  setTypeOfProperty({ name: "Department", id: "3" })
                }
              />
              <Checkbox
                name={"house"}
                label={"House"}
                defaultChecked={typeOfProperty.id === "1"}
                onChange={() => setTypeOfProperty({ name: "House", id: "1" })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesTypeListing;
