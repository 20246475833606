import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "services/apiClient";

export interface PaymentProperty {
  property_id: number;
  currency: string;
  total_price: number;
}

const service = new ApiClient<PaymentProperty>("/create-checkout-session");

const usePayment = (onAdd: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: service.Post,
    onMutate: (newPayment: PaymentProperty) => {
      const previousPayments = queryClient.getQueryData<PaymentProperty[]>(
        ["Payments"] || null
      );

      queryClient.setQueryData<PaymentProperty[]>(["Payments"], (payments) => [
        newPayment,
        ...(payments || []),
      ]);
      onAdd();
      return { previousPayments };
    },
    onSuccess: (savedPayment, newPayment) => {
      queryClient.setQueryData<PaymentProperty[]>(["Payments"], (payments) =>
        payments?.map((payment) =>
          payment === newPayment ? savedPayment : payment
        )
      );
    },
    onError: (_error, _newPayment, context) => {
      if (!context) return;
      queryClient.setQueryData<PaymentProperty[]>(
        ["Payments"],
        context.previousPayments
      );
    },
  });
};

export default usePayment;
