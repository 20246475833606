import { useInfiniteQuery } from "@tanstack/react-query";
import FilterStore from "stores/FilterStore";
import ApiClient from "services/apiClient";
import { StayDataType } from "data/types";
import useUserStore from "stores/userStore";

export interface PropertiesResponse {
  results: StayDataType[];
  meta: {
    totalProperties: number;
    totalPage: number;
    currentPage: number;
  };
}

interface PropertiesQuery {
  address?: string;
  zone?: string;
  city?: string;
  state?: string;
  country?: string;
  type?: string;
  adults?: number;
  children?: number;
  infants?: number;
  tags?: string;
  beds?: number;
  bedrooms?: number;
  bathrooms?: number;
}

const apiClient = new ApiClient<PropertiesResponse>("/properties");

const useFindProperties = (
  pageSize: number,
  PropertyQuery: PropertiesQuery
) => {
  const PropertyQueryStore = FilterStore();
  const { user } = useUserStore();

  return useInfiniteQuery<PropertiesResponse, Error>({
    queryKey: [
      "Properties",
      PropertyQuery,
      PropertyQueryStore.beds,
      PropertyQueryStore.bedrooms,
      PropertyQueryStore.bathrooms,
      PropertyQueryStore.min_price,
      PropertyQueryStore.max_price,
      PropertyQueryStore.category,
      user.currency,
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiClient.getAllWithParams({
        params: {
          address: PropertyQuery.address,
          zone: PropertyQuery.zone,
          city: PropertyQuery.city,
          state: PropertyQuery.state,
          country: PropertyQuery.country,
          type: PropertyQueryStore.type,
          category: PropertyQueryStore.category,
          adults: PropertyQuery.adults,
          children: PropertyQuery.children,
          infants: PropertyQuery.infants,
          tags: PropertyQuery.tags,
          beds: PropertyQueryStore.beds,
          bedrooms: PropertyQueryStore.bedrooms,
          bathrooms: PropertyQueryStore.bathrooms,
          minPrice: PropertyQueryStore.min_price,
          maxPrice: PropertyQueryStore.max_price,
          currency: user.currency,
          page: pageParam,
          pageSize: pageSize,
        },
      }),
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPage } = lastPage.meta;
      return currentPage < totalPage ? currentPage + 1 : undefined;
    },
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });
};

export default useFindProperties;
