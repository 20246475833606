"use client";

import { FC, useEffect, useState } from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import SaleSearchForm from "./(sales-search-form)/SaleSearchForm";
import useFilterStore from "stores/FilterStore";

export type SearchTab =
  | "Stays"
  | "Experiences"
  | "Cars"
  | "Flights"
  | "Rentals"
  | "Sales";

export interface HeroSearchFormSmallProps {
  className?: string;
  defaultTab?: SearchTab;
  onTabChange?: (tab: SearchTab) => void;
  defaultFieldFocus?: StaySearchFormFields;
}
const TABS: SearchTab[] = ["Rentals", "Sales"];

const HeroSearchFormSmall: FC<HeroSearchFormSmallProps> = ({
  className = "",
  defaultTab = "Rentals",
  onTabChange,
  defaultFieldFocus,
}) => {
  const [tabActive, setTabActive] = useState<SearchTab>(defaultTab);
  const { setFilter, type } = useFilterStore();

  useEffect(() => {
    const currentTabByType = type === 0 ? "Rentals" : "Sales";
    setTabActive(currentTabByType);
    if (type === 0) {
      setFilter("max_price", 0);
      setFilter("min_price", 0);
      setFilter("category", 0);
    }
  }, [type]);

  const renderTab = () => {
    return (
      <ul className="h-[88px] flex justify-center space-x-5 sm:space-x-9">
        {TABS.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => {
                setTabActive(tab);
                onTabChange && onTabChange(tab);
                setFilter("type", tab === "Rentals" ? 0 : 1);
              }}
              className={`relative flex-shrink-0 flex items-center cursor-pointer text-base ${
                active
                  ? "text-neutral-900 dark:text-neutral-200 font-medium"
                  : "text-neutral-500 dark:text-neutral-300 "
              } `}
              key={tab}
            >
              <div className="relative select-none">
                <span>{tab}</span>
                {active && (
                  <span className="absolute top-full mt-1 block w-full h-0.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "Rentals":
        return <StaySearchForm defaultFieldFocus={defaultFieldFocus} />;
      case "Sales":
        return <SaleSearchForm defaultFieldFocus={defaultFieldFocus} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchFormSmall ${className}`}
      data-nc-id="HeroSearchFormSmall"
    >
      {renderTab()}
      <div className="mt-2">{renderForm()}</div>
    </div>
  );
};

export default HeroSearchFormSmall;
