import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import CommonLayout from "./CommonLayout";
import useAddListingStore from "stores/addListinigStore";

export interface PageAddListing3Props {}

const PageAddListing3: FC<PageAddListing3Props> = () => {
  const { guest, beds, bathrooms, bedrooms, kitchen, setProperty } =
    useAddListingStore();
  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
    >
      <>
        <h2 className="text-2xl font-semibold">Size of your location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <NcInputNumber
            onChange={(value) => setProperty("guest", value)}
            label="Guests"
            defaultValue={guest}
          />
          <NcInputNumber
            onChange={(value) => setProperty("bedrooms", value)}
            label="Bedroom"
            defaultValue={bedrooms}
          />
          <NcInputNumber
            onChange={(value) => setProperty("beds", value)}
            label="Beds"
            defaultValue={beds}
          />
          <NcInputNumber
            onChange={(value) => setProperty("bathrooms", value)}
            label="Bathroom"
            defaultValue={bathrooms}
          />
          <NcInputNumber
            onChange={(value) => setProperty("kitchen", value)}
            label="Kitchen"
            defaultValue={kitchen}
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
