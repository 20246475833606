import { FC } from "react";
import StayCard from "components/StayCard/StayCard";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { useLocation } from "react-router-dom";
import { InfiniteData } from "@tanstack/react-query";
import { PropertiesResponse } from "hooks/properties/useFindProperties";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StayCardHSkeleton from "components/StayCardH/StayCardHSkeleton";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: InfiniteData<PropertiesResponse>;
  nextPage?: () => void;
  isLoading?: boolean;
  loadSkeleton?: boolean;
  hasNextPage?: boolean;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  nextPage,
  isLoading,
  hasNextPage,
  loadSkeleton,
}) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const getTagName = (id: string | null) => {
    if (!id) return "All";
    switch (id) {
      case "1":
        return "Beachfront villa";
      case "2":
        return "Luxury Villa";
      case "3":
        return "Penthouse";
      case "4":
        return "Mansion";
      case "5":
        return "Golf Course";
      case "6":
        return "Residential Area";
      default:
        return "All";
    }
  };

  const renderSkeletons = () => {
    return Array.from({ length: 8 }, (_, i) => (
      <div key={i}>
        <StayCardHSkeleton className="animate-pulse" />
      </div>
    ));
  };

  const renderStays = () => {
    if (!data) return null;

    return data.pages.map((page) =>
      page.results.map((property) => (
        <div key={property.property_id}>
          <StayCard data={property} />
        </div>
      ))
    );
  };

  const handleButtonClick = () => {
    if (nextPage) {
      nextPage();
    }
  };

  const getHeadingText = () => {
    const tags = query.get("tags");
    const city = query.get("city");
    const zone = query.get("zone");

    if (tags) return `Stays with ${getTagName(tags)}`;
    if (city) return `Stays in ${city}`;
    if (zone) return `Stays in ${zone}`;
    return "Stays";
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading={getHeadingText()} />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {loadSkeleton ? renderSkeletons() : renderStays()}
      </div>

      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary onClick={handleButtonClick}>
          {isLoading
            ? "Loading..."
            : hasNextPage
            ? "Load More"
            : "Nothing more"}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
