import ApiClient from "services/apiClient";
import { PropertiesResponse } from "./useFindProperties";
import { useQuery } from "@tanstack/react-query";
import useUserStore from "stores/userStore";

const apiClient = new ApiClient<PropertiesResponse>("/Properties/WhishList");

const useWislistPropertiesUser = (
  page: number,
  pageSize: number,
  user_id: number
) => {
  const { userWishlist } = useUserStore();
  return useQuery<PropertiesResponse, Error>({
    queryKey: ["Properties", page, pageSize, userWishlist],
    queryFn: () =>
      apiClient.getAllWithParams({
        params: {
          user_id: user_id,
          page: page,
          pageSize: pageSize,
        },
      }),
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });
};

export default useWislistPropertiesUser;
