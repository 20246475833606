import { useMutation } from "@tanstack/react-query";
import ApiClient from "services/apiClient";

export interface AirbnbCalendarResponse {
  unavailableDates: UnavailableDate[];
}

export interface UnavailableDate {
  startDate: Date;
  endDate: Date;
}

type AirbnbCalendarRequest = {
  calendarUrl: string;
};

const useAirbnbCalendar = () => {
  const apiClient = new ApiClient("/airbnbCalendar");

  const airbnbCalendarMutation = useMutation<
    AirbnbCalendarResponse,
    Error,
    AirbnbCalendarRequest
  >(async (request) => {
    const response = await apiClient.Post(request);
    return response as AirbnbCalendarResponse;
  });

  return {
    airbnbCalendar: airbnbCalendarMutation.mutateAsync,
    isLoading: airbnbCalendarMutation.isLoading,
    isError: airbnbCalendarMutation.isError,
    error: airbnbCalendarMutation.error,
  };
};

export default useAirbnbCalendar;
