import { FC } from "react";

const StayCardHSkeleton: FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <div
      className={`nc-StayCardHSkeleton group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardHSkeleton"
    >
      <div className="grid grid-cols-1 md:flex md:flex-row">
        <div className="relative flex-shrink-0 w-full md:w-72 bg-gray-300 animate-pulse">
          <div className="aspect-w-6 aspect-h-5 bg-gray-300"></div>
          <div className="absolute right-3 top-3 w-8 h-8 bg-gray-400 rounded-full"></div>
        </div>
        <div className="flex-grow p-3 sm:p-5 flex flex-col">
          <div className="space-y-2">
            <div className="w-32 h-4 bg-gray-400 rounded"></div>
            <div className="w-48 h-6 bg-gray-400 rounded"></div>
          </div>
          <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
          <div className="hidden sm:grid grid-cols-3 gap-2">
            <div className="space-y-3">
              <div className="flex items-center space-x-3">
                <div className="w-5 h-5 bg-gray-400 rounded-full"></div>
                <div className="w-16 h-4 bg-gray-400 rounded"></div>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-5 h-5 bg-gray-400 rounded-full"></div>
                <div className="w-16 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center space-x-3">
                <div className="w-5 h-5 bg-gray-400 rounded-full"></div>
                <div className="w-16 h-4 bg-gray-400 rounded"></div>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-5 h-5 bg-gray-400 rounded-full"></div>
                <div className="w-16 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center space-x-3">
                <div className="w-5 h-5 bg-gray-400 rounded-full"></div>
                <div className="w-16 h-4 bg-gray-400 rounded"></div>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-5 h-5 bg-gray-400 rounded-full"></div>
                <div className="w-16 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
          <div className="flex justify-between items-end">
            <div className="w-24 h-6 bg-gray-400 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayCardHSkeleton;
