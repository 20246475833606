import { useQuery } from "@tanstack/react-query";
import { StayDataType } from "data/types";
import ApiClient from "services/apiClient";
import useFilterStore from "stores/FilterStore";
import useUserStore from "stores/userStore";

interface PropertiesResponse {
  results: StayDataType[];
  meta: {
    totalProperties: number;
    totalPage: number;
    currentPage: number;
  };
}

const apiClient = new ApiClient<PropertiesResponse>("/properties");

const useGetProperties = (page: number, pageSize: number, category: number) => {
  const { user } = useUserStore();
  const { type } = useFilterStore();
  return useQuery<PropertiesResponse, Error>({
    queryKey: ["Properties", type, category, page, pageSize, user.currency],
    queryFn: () =>
      apiClient.getAllWithParams({
        params: {
          type: type,
          category: category,
          page: page,
          currency: user.currency,
          pageSize: pageSize,
        },
      }),
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });
};

export default useGetProperties;
