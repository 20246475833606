import { useQuery } from "@tanstack/react-query"
import ApiClient from "services/apiClient"

export interface Tag {
    tag_id:   number;
    tag_name: string;
}

const useTags = () => {
    return useQuery<Tag[], Error>({
        queryKey: ["Tags"],
        queryFn: new ApiClient<Tag>('/Tags').getAll,
        staleTime: 1000 * 60 * 5 
    })
}

export default useTags