import { useQuery } from "@tanstack/react-query";
import ApiClient from "services/apiClient";

export interface Category {
  category_id: string;
  description: string;
}

const useCategories = () => {
  return useQuery<Category[], Error>({
    queryKey: ["categories"],
    queryFn: new ApiClient<Category>("/Categories").getAll,
    staleTime: 1000 * 60 * 5,
  });
};

export default useCategories;
