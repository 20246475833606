const getTotalNights = (startDate: Date, endDate: Date): number => {
  // Convertimos las fechas a milisegundos desde el Epoch
  const start = startDate.getTime();
  const end = endDate.getTime();

  // Calculamos la diferencia en milisegundos
  const differenceInMilliseconds = end - start;

  // Convertimos los milisegundos a días (1 día = 24 * 60 * 60 * 1000 milisegundos)
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Redondeamos el resultado al número entero más cercano
  return Math.round(differenceInDays);
};

export default getTotalNights;
