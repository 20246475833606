import useAddWishList from "hooks/users/useAddWishlist";
import useDeleteWishlist from "hooks/users/useDeleteWishlist";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "stores/userStore";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  property_id?: number;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  isLiked = false,
  property_id,
}) => {
  const [likedState, setLikedState] = useState(isLiked);
  const { isLogged, setUserWishlist, userWishlist } = useUserStore();
  const navegation = useNavigate();

  useEffect(() => {
    setLikedState(isLiked);
  }, [isLiked]);

  const onSave = () => {
    console.log("Save");
  };

  const { mutateAsync } = useAddWishList(onSave);
  const { mutateAsync: deleteWishlist } = useDeleteWishlist(onSave);
  const {
    user: { user_id },
  } = useUserStore();

  const handleLike = () => {
    if (!isLogged) {
      // go to login page
      navegation("/signup");
      return;
    }
    setLikedState((prev) => !prev);
    if (isLiked) {
      setUserWishlist(userWishlist.filter((id) => id !== property_id));
      const data = {
        userId: user_id,
        property_id: property_id ? property_id : 0,
      };

      deleteWishlist(data);
    } else {
      if (property_id) {
        setUserWishlist([...userWishlist, property_id]);
      }

      const data = {
        wishlist_id: 0,
        user_id: user_id,
        property_id: property_id ? property_id : 0,
      };
      mutateAsync(data);
    }
  };

  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
        likedState ? "nc-BtnLikeIcon--liked" : ""
      }  ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={() => handleLike()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill={likedState ? "currentColor" : "none"}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
