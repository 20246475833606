import { useQuery } from "@tanstack/react-query"
import ApiClient from "services/apiClient";

export interface PropertyRule {
    rule_id: string;
    rule_name: string;
}


const usePropertyRules = () => {
    return useQuery<PropertyRule[], Error>({
        queryKey: ["propertyRules"],
        queryFn: new ApiClient<PropertyRule>("/Rules").getAll,
        staleTime: 1000 * 60 * 5,
    })
}

export default usePropertyRules;