import { useQuery } from "@tanstack/react-query";
import ApiClient from "services/apiClient";
import { WishlistItem } from "services/WishlistService";

const useAmenities = (user_id: number) => {
  return useQuery<WishlistItem[], Error>({
    queryKey: ["wishlist", user_id] as [string, number],
    queryFn: () => new ApiClient<WishlistItem[]>("/WishList").get(user_id),
    staleTime: 1000 * 60 * 5,
  });
};

export default useAmenities;
