import { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import useCategories from "hooks/properties/useCategories";
import useAddListiningStore from "stores/addListinigStore";
import TagSelector from "shared/TagsSelector/TagSelector";
import useTags from "hooks/properties/useTags";
export interface PageAddListing1Props {}

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const { data: Categories, error } = useCategories();
  const { data: Tags, isLoading } = useTags();
  const { category, name, type, setProperty, addTag, deleteTag } =
    useAddListiningStore();

  if (error) return <div>Error: {error.message}</div>;

  return (
    <CommonLayout
      index="01"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
    >
      <>
        <h2 className="text-2xl font-semibold">Choosing listing categories</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Choose a property category"
            desc="Hotel: Professional hospitality businesses that usually have a unique style or theme defining their brand and decor"
          >
            <Select
              value={category}
              onChange={(e) => setProperty("category", e.target.value)}
            >
              {Categories?.map((category) => (
                <option key={category.category_id} value={category.category_id}>
                  {category.description}
                </option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label="Place name"
            desc="A catchy name usually includes: House name + Room name + Featured property + Tourist destination"
          >
            <Input
              value={name}
              onChange={(e) => setProperty("name", e.target.value)}
              placeholder="Places name"
            />
          </FormItem>
          <FormItem
            label="Listing Type"
            desc="Choose Sale or Rent for your property "
          >
            <Select
              value={type}
              onChange={(e) => setProperty("type", e.target.value)}
            >
              <option value="0">Rental</option>
              <option value="1">Sale</option>
            </Select>
          </FormItem>
          <FormItem
            label="Property Tags"
            desc="Tags with which the user can better identify the type and amenities of the property"
          >
            {isLoading ? (
              <span>loading...</span>
            ) : (
              <TagSelector
                onChange={addTag}
                onRemove={deleteTag}
                tags={Tags ? Tags : []}
              />
            )}
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
