import { FC, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import usePropertyRules, {
  PropertyRule,
} from "hooks/properties/usePropertyRules";
import useAddListingStore from "stores/addListinigStore";

export interface PageAddListing5Props {}

const PageAddListing5: FC<PageAddListing5Props> = () => {
  const newRule = useRef<HTMLInputElement>(null);
  const { addRule, deleteRule, propertyRules } = useAddListingStore();
  const { data: rules, error, isLoading } = usePropertyRules();

  if (error) return <span>{error.message}</span>;

  const handleAddRule = () => {
    if (newRule.current?.value) {
      addRule({
        rule_id: newRule.current.value,
        rule_name: newRule.current.value,
      });
      newRule.current.value = "";
    }
  };

  const handleSelectRule = (rule: PropertyRule, value: string) => {
    if (value === "1") {
      // is allow
      console.log("agregar");
      addRule(rule);
    } else {
      // not allow
      if (propertyRules.some((r) => r.rule_id === rule.rule_id)) {
        deleteRule(rule.rule_id);
        console.log("eliminar");
      }
    }
    console.log(propertyRules);
  };

  const handleDeleteRule = (idRule: string) => {
    deleteRule(idRule);
  };

  if (isLoading) return <div>Loading...</div>;

  const renderRadio = (rule: PropertyRule, value: string, label: string) => {
    return (
      <div className="flex items-center">
        <input
          name={rule.rule_id + rule.rule_name}
          id={rule.rule_id}
          type="radio"
          value={value}
          onChange={() => handleSelectRule(rule, value)}
          defaultChecked={propertyRules.some((r) => r.rule_id === rule.rule_id)}
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={rule.rule_id + rule.rule_name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderNoInclude = (text: string, id: string) => {
    return (
      <div className="flex items-center justify-between py-3">
        <span className="text-neutral-6000 dark:text-neutral-400 font-medium">
          {text}
        </span>
        <i
          onClick={() => handleDeleteRule(id)}
          className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"
        ></i>
      </div>
    );
  };

  return (
    <CommonLayout
      index="05"
      backtHref="/add-listing-4"
      nextHref="/add-listing-6"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">
            Set house rules for your guests{" "}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Guests must agree to your house rules before they book.
          </span>
        </div>
        {/* FORM */}
        <div className="space-y-8 ">
          {/* ITEM */}
          <div>
            {!isLoading &&
              rules?.map((rule) => (
                <div className="flex justify-between" key={rule.rule_id}>
                  <label className="text-lg font-semibold" htmlFor="">
                    {rule.rule_name}:
                  </label>
                  <div className="justify-items-end mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {renderRadio(rule, "0", "Do not allow")}
                    {renderRadio(rule, "1", "Allow")}
                  </div>
                </div>
              ))}
          </div>

          {/* ----------- */}
          <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
          <span className="block text-lg font-semibold">Additional rules</span>
          <div className="flow-root">
            <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
              {!isLoading &&
                propertyRules.map((rule) =>
                  renderNoInclude(rule.rule_name, rule.rule_id)
                )}
            </div>
          </div>
          <form
            className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5"
            onSubmit={(event) => {
              event.preventDefault();
              handleAddRule();
            }}
          >
            <Input
              ref={newRule}
              className="!h-full"
              placeholder="No smoking..."
            />
            <ButtonPrimary type="submit" className="flex-shrink-0">
              <i className="text-xl las la-plus"></i>
              <span className="ml-3">Add tag</span>
            </ButtonPrimary>
          </form>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing5;
