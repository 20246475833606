import React, { useState } from "react";
import ApiClientImages from "services/apiClientImages";
interface Props {
  OnceImage?: boolean;
  MultiImage?: boolean;
  onDropFiles: (url: string[]) => void;
}

const InputFile = ({ OnceImage, MultiImage = true, onDropFiles }: Props) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleSumbit = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    try {
      const droppedFiles = Array.from(e.dataTransfer.files);

      const formData = new FormData();

      if (OnceImage) {
        formData.append("images", droppedFiles[0]);
      } else {
        droppedFiles.forEach((file) => {
          formData.append("images", file);
        });
      }
      const apiClient = new ApiClientImages("/PropertiesImages");
      const response = (await apiClient.Post(formData)) as string[];
      onDropFiles(response);
    } catch (e) {
      console.log("Error:", e);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };
  return (
    <div
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleSumbit(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      className={`${
        isDragOver
          ? "border-4 border-dashed border-indigo-300 dark:border-primary-500"
          : "border-2 border-dashed"
      } p-12 rounded-lg flex flex-col items-center justify-center`}
    >
      <div className="space-y-1 text-center">
        <svg
          className="mx-auto h-12 w-12 text-neutral-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
          <label
            htmlFor="file-upload-2"
            className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
          >
            <span>Upload a file</span>
            <input
              id="file-upload-2"
              name="file-upload-2"
              type="file"
              className="sr-only"
            />
          </label>
          <p className="pl-1">or drag and drop</p>
        </div>
        <p className="text-xs text-neutral-500 dark:text-neutral-400">
          PNG, JPG, GIF up to 10MB
        </p>
      </div>
    </div>
  );
};
export default InputFile;
