import DatePicker from "react-datepicker";
import { FC, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import useFilterStore from "stores/FilterStore";

export interface StayDatesRangeInputProps {
  className?: string;
  onChange?: (dates: { startDate: Date; endDate: Date }) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
  onChange = () => {},
}) => {
  const { check_in, check_out, setFilter } = useFilterStore();

  const [startDate, setStartDate] = useState<Date | null>(
    check_in ? check_in : new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    check_out ? check_out : new Date()
  );

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setFilter("check_in", start);
    setFilter("check_out", end);
    // que se seleccione la fecha de inicio y la fecha de fin
    if (start && end) {
      onChange({ startDate: start, endDate: end });
    }
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
