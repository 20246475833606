import React from "react";
import { Image } from "services/imagesService";

interface Props {
  files: Image[] | Image;
}

const NclImageGrid = ({ files }: Props) => {
  const isSingleImage = !Array.isArray(files);
  return (
    <div className="grid grid-cols-3 gap-4">
      {isSingleImage ? (
        <img
          key={files.url + files.id}
          src={`${files.url}`}
          alt="uploaded"
          className="w-full h-48 object-cover rounded-lg"
        />
      ) : (
        files.map((file) => (
          <img
            key={file.url}
            src={`${file.url}`}
            alt="uploaded"
            className="w-full h-48 object-cover rounded-lg"
          />
        ))
      )}
    </div>
  );
};

export default NclImageGrid;
