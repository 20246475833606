import ApiClient from "services/apiClient"
import { Images } from "./usePropertyImages"
import { useQuery } from "@tanstack/react-query"

const apiclient = new ApiClient<Images[]>(`/images`)
const useImages = (id: string) => {
    return useQuery({
        queryKey: ["Images", id],
        queryFn: () => apiclient.get(id)
    })
}

export default useImages