import { FC } from "react";
import CommonLayout from "./CommonLayout";
import InputFile from "shared/InputFile/InputFile";
import NclImageGrid from "shared/NcImage/NclImageGrid";
import useAddListingStore from "stores/addListinigStore";

export interface PageAddListing7Props {}

const PageAddListing7: FC<PageAddListing7Props> = () => {
  const { images, coverImage, addCoverImage, addImages } = useAddListingStore();

  const handleSubmitCoverImage = (file: string[]) => {
    addCoverImage(file[0]);
  };

  const handleSubmitImages = (files: string[]) => {
    files.forEach((img) => {
      addImages(img);
    });
  };

  return (
    <CommonLayout
      index="07"
      backtHref="/add-listing-6"
      nextHref="/add-listing-8"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Pictures of the place</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            A few beautiful photos will help customers have more sympathy for
            your property.
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <div>
            <span className="text-lg font-semibold">Cover image</span>
            <div className="mt-5 ">
              {coverImage.id === 0 ? (
                <InputFile onDropFiles={handleSubmitCoverImage} OnceImage />
              ) : (
                <NclImageGrid files={coverImage} />
              )}
            </div>
          </div>
          {/* ----------------- */}
          <div>
            <span className="text-lg font-semibold">Pictures of the place</span>
            <div className="mt-5 ">
              {images.length === 0 ? (
                <InputFile onDropFiles={handleSubmitImages} />
              ) : (
                <NclImageGrid files={images} />
              )}
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing7;
