import { useMutation, useQueryClient } from "@tanstack/react-query";
import PropertyService, { Property } from "services/PropertyService";

const useAddProperty = (onAdd: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PropertyService.Post,
    onMutate: (newProperty: Property) => {
      console.log(newProperty);
      const previousProperties = queryClient.getQueryData<Property[]>(
        ["Properties"] || null
      );

      queryClient.setQueryData<Property[]>(["Properties"], (properties) => [
        newProperty,
        ...(properties || []),
      ]);
      onAdd();
      return { previousProperties };
    },
    onSuccess: (savedProperty, newProperty) => {
      queryClient.setQueryData<Property[]>(["Properties"], (properties) =>
        properties?.map((property) =>
          property === newProperty ? savedProperty : property
        )
      );
    },
    onError: (_error, _newTodo, context) => {
      if (!context) return;
      queryClient.setQueryData<Property[]>(
        ["Properties"],
        context.previousProperties
      );
    },
  });
};

export default useAddProperty;
