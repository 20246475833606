import React, { useState, FC, useEffect } from "react";
import Slider from "rc-slider";
import convertNumbThousand from "utils/convertNumbThousand";
import useFilterStore from "stores/FilterStore";
import useOutsideAlerter from "hooks/useOutsideAlerter";

export interface SalePriceRangeInputProps {
  onChange?: (data: any) => void;
  className?: string;
  fieldClassName?: string;
  autoFocus?: boolean;
  submitLink?: string;
}

const SalePriceRangeInput: FC<SalePriceRangeInputProps> = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding--small ]",
  className = "",
  autoFocus = false,
  submitLink,
}) => {
  const [rangePrices, setRangePrices] = useState([1000000, 1500000]);
  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const { setFilter } = useFilterStore();

  useOutsideAlerter(refContainer, () => setIsOpen(false));

  useEffect(() => {
    setFilter("min_price", rangePrices[0]);
    setFilter("max_price", rangePrices[1]);
  }, [rangePrices]);

  // const handleSubmit = () => {
  //   const filters: Filter = {
  //     min_price: rangePrices[0],
  //     max_price: rangePrices[1],
  //     zone: zone,
  //     state: state,
  //     city: city,
  //     category: category?.toString(),
  //     type: type,
  //   };

  //   const filteredParams: Partial<Filter> = Object.entries(filters).reduce(
  //     (acc, [key, value]) => {
  //       if (value) {
  //         acc[key as keyof Filter] = value; // Añadir clave y valor si el valor no está vacío
  //       }
  //       return acc;
  //     },
  //     {} as Partial<Filter>
  //   );

  //   const queryString = new URLSearchParams(
  //     filteredParams as Record<string, string>
  //   ).toString();
  //   navigate(`/listing-stay?${queryString}`);
  // };

  return (
    <div className={`flex z-10 relative ${className}`} ref={refContainer}>
      <div
        className={`flex z-10 relative flex-1 text-left justify-between items-center focus:outline-none cursor-pointer ${
          isOpen ? "nc-hero-field-focused--2" : ""
        }`}
      >
        <div
          className={`${fieldClassName} flex-1`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex-1 text-left">
            <span className="block font-semibold">
              {convertNumbThousand(rangePrices[0])}-{" "}
              {convertNumbThousand(rangePrices[1])}
            </span>
            <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
              Price range
            </span>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-10 bg-white dark:bg-neutral-800"></div>
      )}

      {isOpen && (
        <div className="absolute right-0 z-30 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
          <div className="relative flex flex-col space-y-8">
            <div className="space-y-5">
              <span className="font-medium">Range Price </span>
              <Slider
                range
                className="text-red-400"
                min={1000000}
                max={10000000}
                defaultValue={[rangePrices[0], rangePrices[1]]}
                allowCross={false}
                step={10000}
                onChange={(e) => setRangePrices(e as number[])}
              />
            </div>

            <div className="flex justify-between space-x-3">
              <div>
                <label
                  htmlFor="minPrice"
                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                >
                  Min price
                </label>
                <div className="mt-1 relative rounded-md">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-neutral-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    disabled
                    name="minPrice"
                    id="minPrice"
                    className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                    value={convertNumbThousand(rangePrices[0])}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="maxPrice"
                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                >
                  Max price
                </label>
                <div className="mt-1 relative rounded-md">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-neutral-500 sm:text-sm">$</span>
                  </div>
                  <input
                    disabled
                    type="text"
                    name="maxPrice"
                    id="maxPrice"
                    className="focus:ring-primary-500 focus:border-priring-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                    value={convertNumbThousand(rangePrices[1])}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalePriceRangeInput;
