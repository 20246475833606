import { useQuery } from "@tanstack/react-query";
import { StayDataDetail } from "data/types";
import ApiClient from "services/apiClient";
import useUserStore from "stores/userStore";

const apiClient = new ApiClient<StayDataDetail>("/property");

const useProperty = (id: string) => {
  const { user } = useUserStore();
  return useQuery<StayDataDetail, Error>({
    queryKey: ["Property", id, user.currency],
    queryFn: () =>
      apiClient.getWithParams(id, {
        params: {
          currency: user.currency,
        },
      }),
  });
};

export default useProperty;
