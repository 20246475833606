import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { PropertyWithRate } from "data/types";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import useFilterStore from "stores/FilterStore";
import getPricePerNight from "utils/GetCurrentPrice";

export interface StayCardHProps {
  className?: string;
  data: PropertyWithRate;
}

const StayCardH: FC<StayCardHProps> = ({ className = "", data }) => {
  const {
    images,
    category_description,
    address,
    title,
    property_id,
    max_guests,
    number_of_bedrooms,
    number_of_baths,
    number_of_beds,

    rates,
  } = data;

  const { check_in, check_out } = useFilterStore();

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={images}
          uniqueID={`StayCardH_${property_id}`}
          href={`/listing-stay-detail/${title}/${property_id}`}
        />
        <BtnLikeIcon isLiked={false} className="absolute right-3 top-3" />
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {max_guests} guests
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-bed text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {number_of_beds} beds
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {number_of_baths} baths
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-door-open text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {number_of_bedrooms} bedrooms
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {category_description} in {address}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
          <span className="text-base font-semibold text-secondary-500">
            {
              getPricePerNight(
                rates,
                check_in ? check_in : new Date(),
                check_out ? check_out : new Date()
              ).Text
            }
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /night
            </span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link
        to={`/listing-stay-detail/${title}/${property_id}`}
        className="absolute inset-0"
      ></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
