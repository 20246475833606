import { useMutation, useQueryClient } from "@tanstack/react-query";
import userService, { UserData } from "services/UserService";
import useUserStore from "stores/userStore";

const useSignUp = (onAdd: () => void) => {
  const queryClient = useQueryClient();
  const { setUser, setIsLogged } = useUserStore();
  return useMutation({
    mutationFn: userService.Post,
    onMutate: (newUser: UserData) => {
      const previousUser = queryClient.getQueryData<UserData>(["user"]);
      queryClient.setQueryData<UserData>(["user"], newUser);

      return { previousUser };
    },
    onSuccess: (savedUser, _newUser) => {
      queryClient.setQueryData<UserData>(["user"], savedUser);
      setUser(savedUser);
      setIsLogged(true);
      console.log(savedUser);
      localStorage.setItem("User", JSON.stringify(savedUser));
      onAdd();
    },
    onError: (_error, _newTodo, context) => {
      if (!context) return;
      queryClient.setQueryData<UserData>(["user"], context.previousUser);
    },
  });
};

export default useSignUp;
