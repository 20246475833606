import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export interface AddressCoord {
  lat: number;
  lng: number;
}
export interface AddressLocation {
  coords: AddressCoord;
  country: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
}

const usePlacesAutoComplete = () => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const getLocations = async (
    addressValue: string
  ): Promise<AddressLocation> => {
    clearSuggestions();
    try {
      const results = await getGeocode({ address: addressValue });
      const { lat, lng } = getLatLng(results[0]);
      const adress = results[0].address_components;
      const getAddressComponent = (types: string[]) => {
        const component = adress.find((component) =>
          types.some((type) => component.types.includes(type))
        );
        return component ? component.long_name : "";
      };

      console.log(adress);

      const location: AddressLocation = {
        coords: { lat, lng },
        address: addressValue,
        city: getAddressComponent(["locality"]),
        state: getAddressComponent(["administrative_area_level_1"]),
        country: getAddressComponent(["country"]),
        postal_code: getAddressComponent(["postal_code"]),
      };
      return location;
    } catch (error) {
      console.log("Error: ", error);
      return {
        coords: { lat: 0, lng: 0 },
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
      };
    }
  };

  return {
    value,
    data,
    setValue,
    ready,
    status,
    getLocations,
  };
};

export default usePlacesAutoComplete;
