import ButtonPrimary from "shared/Button/ButtonPrimary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";

import { FormEvent } from "react";

interface FormCheckOutProps {
  onPayment: () => void;
}

const FormCheckOut: React.FC<FormCheckOutProps> = ({ onPayment }) => {
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    onPayment();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-row">
        <div className="w-full mr-2">
          <Label>Name</Label>
          <Input
            placeholder="Card Name"
            className="h-11"
            name="firstName"
            type="text"
          />
        </div>
        <div className="">
          <Label>Zip Code</Label>
          <Input
            placeholder="Zip Code"
            className="h-11"
            name="ZipCode"
            type="number"
          />
        </div>
      </div>
      <div className="mb-3">
        <Label>Card Number</Label>
      </div>

      {/* dos input en una fila */}
      <div className="grid grid-cols-2 gap-3 md:grid-cols-3">
        <div>
          <Label>Expiry</Label>
        </div>
        <div>
          <Label>CVC</Label>
        </div>
      </div>
      <div>
        <ButtonPrimary className="mt-3" type="submit">
          Confirm And Pay
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default FormCheckOut;
