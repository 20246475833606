import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface ModalContactMobileProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
}

const ModalContactMobile: FC<ModalContactMobileProps> = ({
  renderChildren,
}) => {
  const [showModal, setShowModal] = useState(false);

  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full flex-1 flex flex-col justify-between overflow-auto">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 py-1 flex flex-col ">
                      <div className="flex-1 bg-white dark:bg-neutral-900">
                        {/* Contact form */}
                        <div className="flex flex-col items-center justify-center p-4">
                          <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            Contact Us
                          </h2>
                          <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-1">
                            Fill out the form below and we will get back to you
                            as soon as possible.
                          </p>
                          <form className="mt-4 w-full">
                            <div className="mb-4">
                              <input
                                type="text"
                                placeholder="Name"
                                className="w-full p-3 border border-neutral-200 dark:border-neutral-6000 rounded-xl focus:ring-0 focus:border-primary-600"
                              />
                            </div>
                            <div className="mb-4">
                              <input
                                type="email"
                                placeholder="Email"
                                className="w-full p-3 border border-neutral-200 dark:border-neutral-6000 rounded-xl focus:ring-0 focus:border-primary-600"
                              />
                            </div>
                            <div className="mb-4">
                              <textarea
                                placeholder="Message"
                                className="w-full p-3 border border-neutral-200 dark:border-neutral-6000 rounded-xl focus:ring-0 focus:border-primary-600"
                              ></textarea>
                            </div>
                            <div className="mb-4">
                              <button className="w-full bg-primary-600 text-white py-3 rounded-xl">
                                Send
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalContactMobile;
