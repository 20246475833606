import __blogs from "./jsons/__blogs.json";
import { BlogDataType } from "./types";

const Blogs_Data = __blogs.map((post, index): BlogDataType => {
  return {
    ...post,
    id: index,
  };
});

const Blog_Data_By_Id = Blogs_Data.reduce((acc, post) => {
  acc[post.id] = post;
  return acc;
}, {} as { [key: number]: BlogDataType });

export { Blogs_Data, Blog_Data_By_Id };
