import { useQuery } from "@tanstack/react-query"
import ApiClient from "services/apiClient";

export interface Amenity {
    amenity_id: string;
    amenity_name: string;
    category: string;
}

const useAmenities = () => {
    return useQuery<Amenity[], Error>({
        queryKey: ["amenities"],
        queryFn: new ApiClient<Amenity>("/Amenities").getAll,
        staleTime: 1000 * 60 * 5,
    })
}

export default useAmenities;