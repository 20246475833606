import { FC, Fragment, useMemo, useState } from "react";
import StartRating from "components/StartRating/StartRating";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import { useParams } from "react-router-dom";
import useProperty from "hooks/properties/useProperty";
import AmenityIcon from "components/Icons/AmenityIcons";
import {
  GoogleMap,
  Libraries,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import getPricePerNight from "utils/GetCurrentPrice";
import useFilterStore from "stores/FilterStore";
import getTotalNights from "utils/getTotalNights";
import { toInteger } from "lodash";
import convertNumbThousand from "utils/convertNumbThousand";
import { GuestsObject } from "components/HeroSearchForm/type";
import useUserStore from "stores/userStore";

const StayDetailPageContainer: FC<{}> = () => {
  //

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const { id } = useParams();
  const { data: Property, isLoading, error } = useProperty(id!);
  const { check_in, check_out, adults, children, infants } = useFilterStore();

  const [childrensGuest, setChildren] = useState(children ? children : 0);
  const [adultsGuest, setAdults] = useState(adults ? adults : 6);
  const [infantsGuest, setInfants] = useState(infants ? infants : 0);
  const { isLogged } = useUserStore();

  const onChangeGuests = (value: GuestsObject) => {
    setAdults(value.guestAdults ? value.guestAdults : 1);
    setChildren(value.guestChildren ? value.guestChildren : 0);
    setInfants(value.guestInfants ? value.guestInfants : 0);
  };

  function gotoCheckout() {
    if (isLogged) {
      router(
        `/checkout/${Property?.title}/${Property?.property_id}/?check_in=${check_in}&check_out=${check_out}&adults=${adultsGuest}&children=${childrensGuest}&infants=${infantsGuest}`
      );
    } else {
      router("/signup");
    }
  }

  const libraries: Libraries = useMemo(() => ["places"], []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD2fzmBVhIkw7AgrIY_tIhNxMl4nB3rAmc",
    libraries: libraries,
  });
  if (!isLoaded || isLoading) {
    return (
      <>
        <header className="rounded-md sm:rounded-xl animate-pulse">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden bg-gray-300">
              <div className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full bg-gray-300"></div>
            </div>
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden bg-gray-300 ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 bg-gray-300"></div>
              </div>
            ))}
            <div className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-200 text-neutral-500 z-10">
              <div className="w-5 h-5 bg-gray-300 rounded"></div>
              <span className="ml-2 text-neutral-800 text-sm font-medium bg-gray-300 rounded h-5 w-24"></span>
            </div>
          </div>
        </header>
      </>
    );
  }

  if (error || !Property) throw error;

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={Property.category_description} />
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          <span className="text-3xl font-semibold">
            $
            {
              getPricePerNight(
                Property.rates,
                check_in ? new Date(check_in) : new Date(),
                check_out ? new Date(check_out) : new Date()
              ).Text
            }
          </span>
        </h2>
        <span>{Property.title}</span>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {Property.city}</span>
          </span>
        </div>

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {Property.max_guests}{" "}
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {Property.number_of_beds}{" "}
              <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {Property.number_of_baths}{" "}
              <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              {Property.number_of_bedrooms}{" "}
              <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {Property.description}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}

        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
          {Property.amenities
            ?.filter((_, i) => i < 12)
            .map((item) => (
              <div
                key={item.amenity_id}
                className="flex items-center space-x-3"
              >
                <AmenityIcon amenity={item.amenity_name} />
                <span className=" ">{item.amenity_name}</span>
              </div>
            ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more {Property.amenities.length} amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Property.amenities
                      ?.filter((_, i) => i < 1212)
                      .map((item) => (
                        <div
                          key={item.amenity_name}
                          className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                        >
                          <AmenityIcon amenity={item.amenity_name} />
                          <span>{item.amenity_name}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  // const renderSection4 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Room Rates </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}
  //       <div className="flow-root">
  //         <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Friday - Sunday</span>
  //             <span>$219</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Rent by month</span>
  //             <span>-8.34 %</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Minimum number of nights</span>
  //             <span>1 night</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Max number of nights</span>
  //             <span>90 nights</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection5 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Host Information</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* host */}
  //       <div className="flex items-center space-x-4">
  //         <Avatar
  //           hasChecked
  //           hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
  //           sizeClass="h-14 w-14"
  //           radius="rounded-full"
  //         />
  //         <div>
  //           <a className="block text-xl font-medium" href="##">
  //             Kevin Francis
  //           </a>
  //           <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
  //             <StartRating />
  //             <span className="mx-2">·</span>
  //             <span> 12 places</span>
  //           </div>
  //         </div>
  //       </div>

  //       {/* desc */}
  //       <span className="block text-neutral-6000 dark:text-neutral-300">
  //         Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
  //         accommodation, an outdoor swimming pool, a bar, a shared lounge, a
  //         garden and barbecue facilities...
  //       </span>

  //       {/* info */}
  //       <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //             />
  //           </svg>
  //           <span>Joined in March 2016</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
  //             />
  //           </svg>
  //           <span>Response rate - 100%</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
  //             />
  //           </svg>

  //           <span>Fast response - within a few hours</span>
  //         </div>
  //       </div>

  //       {/* == */}
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       <div>
  //         <ButtonSecondary href="/author">See host profile</ButtonSecondary>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection6 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* Content */}
  //       <div className="space-y-5">
  //         <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
  //         <div className="relative">
  //           <Input
  //             fontClass=""
  //             sizeClass="h-16 px-4 py-3"
  //             rounded="rounded-3xl"
  //             placeholder="Share your thoughts ..."
  //           />
  //           <ButtonCircle
  //             className="absolute right-2 top-1/2 transform -translate-y-1/2"
  //             size=" w-12 h-12 "
  //           >
  //             <ArrowRightIcon className="w-5 h-5" />
  //           </ButtonCircle>
  //         </div>
  //       </div>

  //       {/* comment */}
  //       <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <CommentListing className="py-8" />
  //         <div className="pt-8">
  //           <ButtonSecondary>View more 20 reviews</ButtonSecondary>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {Property.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <GoogleMap
              zoom={10}
              center={{ lat: Property.lat, lng: Property.lng }}
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
            >
              <Marker position={{ lat: Property.lat, lng: Property.lng }} />
            </GoogleMap>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>12:00 pm- 3:00 pm</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>11:00 am - 12:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                keep the landscape and environment green and clean by not
                littering, not using stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {
              getPricePerNight(
                Property.rates,
                check_in ? new Date(check_in) : new Date(),
                check_out ? new Date(check_out) : new Date()
              ).Text
            }
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
          <StartRating />
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            excludeDates={Property.blockedDates}
            className="flex-1 z-[11]"
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            defaultValue={{
              guestAdults: adultsGuest,
              guestChildren: childrensGuest,
              guestInfants: infantsGuest,
            }}
            onChange={(e) => onChangeGuests(e)}
            className="flex-1"
            maxGuest={toInteger(Property.max_guests)}
          />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              $
              {
                getPricePerNight(
                  Property.rates,
                  check_in ? check_in : new Date(),
                  check_out ? check_out : new Date()
                ).Text
              }{" "}
              x{" "}
              {getTotalNights(
                check_in ? check_in : new Date(),
                check_out ? check_out : new Date()
              )}{" "}
              night
            </span>
            <span>
              $
              {convertNumbThousand(
                getPricePerNight(
                  Property.rates,
                  check_in ? check_in : new Date(),
                  check_out ? check_out : new Date()
                ).Number *
                  getTotalNights(
                    check_in ? check_in : new Date(),
                    check_out ? check_out : new Date()
                  )
              )}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              $
              {convertNumbThousand(
                getPricePerNight(
                  Property.rates,
                  check_in ? check_in : new Date(),
                  check_out ? check_out : new Date()
                ).Number *
                  getTotalNights(
                    check_in ? check_in : new Date(),
                    check_out ? check_out : new Date()
                  )
              )}
            </span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary onClick={() => gotoCheckout()}>Reserve</ButtonPrimary>
      </div>
    );
  };

  const renderSidebarContactProperty = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span>
            <span className="text-xl font-semibold">
              Contact us for more information.
            </span>
          </span>
        </div>

        {/* FORM */}
        <form className="flex flex-col">
          {/* Row 1: Email */}
          <div className="flex flex-col">
            <label
              htmlFor="email"
              className="text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 p-2 border border-neutral-300 dark:border-neutral-600 rounded-md"
              required
            />
          </div>

          {/* Row 2: Nombre y Teléfono */}
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="flex flex-col flex-1">
              <label
                htmlFor="name"
                className="text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Nombre
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 p-2 border border-neutral-300 dark:border-neutral-600 rounded-md"
                required
              />
            </div>
            <div className="flex flex-col flex-1 mt-4 md:mt-0">
              <label
                htmlFor="phone"
                className="text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Teléfono
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="mt-1 p-2 border border-neutral-300 dark:border-neutral-600 rounded-md"
                required
              />
            </div>
          </div>

          {/* Row 3: Mensaje */}
          <div className="flex flex-col">
            <label
              htmlFor="message"
              className="text-sm font-medium text-neutral-700 dark:text-neutral-300"
            >
              Mensaje
            </label>
            <textarea
              id="message"
              name="message"
              className="mt-1 p-2 border border-neutral-300 dark:border-neutral-600 rounded-md"
              rows={4}
              defaultValue="Hello! I'd like to be contacted regarding the property for sale that I saw on PVR PROPERTIES"
              required
            />
          </div>
        </form>
        {/* SUBMIT */}
        <ButtonPrimary>Contact</ButtonPrimary>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={`${Property.images[0].image_url}`}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {Property.images
            .filter((_, i) => i >= 1 && i < 5)
            .map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <img
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={`${item.image_url}` || ""}
                    alt=""
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {/* {renderSection4()} */}
          {Property.type === 0 ? <SectionDateRange /> : null}
          {/* {Property.type === 0 ? renderSection6() : null} */}
          {renderSection7()}
          {Property.type === 0 ? renderSection8() : null}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">
            {
              Property.type === 0
                ? renderSidebar()
                : renderSidebarContactProperty()
              // renderSidebar()
            }
          </div>
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
